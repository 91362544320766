<template>
  <div class="exercise-book">
    <div class="go-back">
      <span @click="prev">{{ i18n.Home.Home }}</span>
    </div>
    <div class="exercise-book-content">
      <div class="exercise-book-header">
        <h3>{{ i18n.Home.exerciseBook }}</h3>
        <div>
          <el-select
            class="class-frame"
            v-model="classId"
            clearable
            @change="handleFilterChange"
            size="small"
            :placeholder="i18n.Home.class"
          >
            <el-option
              v-for="item in classOptions"
              :key="item.class_id"
              :label="item.class_name"
              :value="item.class_id"
            ></el-option>
          </el-select>

          <!-- 按老师筛选 -->
          <el-select
            class="teacher-frame"
            v-model="teachid"
            clearable
            @change="handleFilterChange"
            size="small"
            :placeholder="$t('m.filtrateByTeacher')"
          >
            <el-option
              v-for="item in teachOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <ul v-loading="loading">
        <template v-if="list && list.length">
          <li v-for="item of list" :key="item.id" @click="clickViewGo(item)">
            <img :src="item.teacher_info.img_url" />
            <div class="exercise-book-text-content">
              <div class="exercise-book-text">
                <div class="name">
                  {{ item.teacher_info.name
                  }}<span>{{ i18n.Home.teacher }}</span>
                </div>
                <div>
                  {{ i18n.Home.already }}{{ item.finsh_nums
                  }}{{ i18n.Home.completion }}
                </div>
              </div>
              <div class="exercise-book-state">
                <div class="exercise-book-title-content">
                  <span class="exercise-book-title-text">{{ item.title }}</span>
                  <span class="new-tag" v-if="item.is_new_flag">NEW</span>
                </div>
                <div v-if="item.is_finsh" class="view-btn look-btn">
                  {{ i18n.Home.see }}
                </div>
                <div v-else class="view-btn finsh-btn">
                  {{ i18n.Home.finish }}
                </div>
              </div>
              <div>
                <span class="exercise-book-time"
                  >{{ i18n.Home.Release }}:{{ item.start_time }}</span
                >
                <span class="exercise-book-time"
                  >{{ i18n.Home.deadline }}{{ item.end_time }}</span
                >
              </div>
            </div>
          </li>
        </template>
      </ul>
      <Empty v-if="!loading && (!list || !list.length)" />
    </div>
    <el-pagination
      v-if="list && list.length"
      small
      layout="prev, pager, next"
      class="exercise-book-pagination"
      @current-change="handlePageChange"
      :page-count="pagination.pageCount"
      :page-size="pagination.pageSize"
      :current-page.sync="pagination.currentPage"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  apiUserMyJobList,
  apiGetClassTeacher,
  apiMyClassList,
} from '@/api/api.js'
import Empty from '@/components/Empty'

export default {
  components: {
    Empty,
  },
  data() {
    return {
      list: [],
      teachOptions: [],
      classOptions: [],
      teachid: '',
      classId: '',
      pagination: {
        pageCount: 1,
        currentPage: 1,
        pageSize: 5,
      },
      loading: false,
    }
  },
  created() {
    this.classId = Number(this.$route.query.classId) || ''

    this.fetchList()
    this.getClassTeacher()
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },

  methods: {
    prev() {
      this.$router.push({ name: 'home' })
    },
    handleFilterChange() {
      this.pagination.currentPage = 1
      this.fetchList()
    },
    handlePageChange(page) {
      this.pagination.currentPage = page

      this.fetchList()
    },
    fetchList() {
      if (this.loading) {
        return
      }
      this.loading = true

      apiUserMyJobList({
        page: this.pagination.currentPage,
        page_size: this.pagination.pageSize,
        teacher_id: this.teachid,
        class_id: this.classId,
      })
        .then((res) => {
          if (res.code !== 200) {
            return
          }
          this.list = res.data.list
          this.pagination.pageCount = res.data.pager.page_cnt
        })
        .finally(() => {
          this.loading = false
        })
    },
    getClassTeacher() {
      apiGetClassTeacher().then((res) => {
        this.teachOptions = res.data
      })
      apiMyClassList().then((res) => {
        this.classOptions = res.data
      })
    },
    clickViewGo(item) {
      this.$router.push({ name: 'ExerciseBookDetail', query: { id: item.id } })
    },
  },
}
</script>

<style lang="scss">
.el-select-dropdown__item.selected {
  color: #3b86ff;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: rgba(193, 230, 254, 0.4);
}
.exercise-book {
  .go-back {
    color: #3b86ff;
    padding: 24px 0 20px;
    font-weight: 400;
    font-size: 14px;
    span {
      display: inline-block;
      cursor: pointer;
      &::before {
        content: '<';
        margin-right: 10px;
      }
    }
  }
  .class-frame {
    margin-right: 20px;
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #3b86ff;
  }
  .el-select .el-input__inner:focus {
    border-color: #dcdfe6;
  }
  .el-select .el-input__inner {
    &:hover {
      border-color: #3b86ff;
    }
  }

  .exercise-book-content {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    .exercise-book-title-content {
      display: flex;
      align-items: center;
    }
    .exercise-book-title-text {
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      overflow: hidden;
      max-width: 620px;
    }
    .new-tag {
      display: inline-block;
      width: 44px;
      height: 18px;
      line-height: 18px;
      background: #ffd2dd;
      border-radius: 10px;
      font-size: 12px;
      color: #ad1625;
      margin-left: 16px;
      text-align: center;
    }
    ul {
      padding: 0 40px;
      li {
        display: flex;
        padding-top: 28px;
        color: #a4afb7;
        font-size: 12px;
        border-bottom: 1px solid #ebebf2;
        cursor: pointer;
        &:last-child {
          .exercise-book-text-content {
            border: none;
          }
        }
        img {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          background-color: #eee;
          margin-right: 12px;
        }
        .name {
          font-size: 14px;
          color: #7c8084;
          span {
            color: #a4afb7;
            font-size: 12px;
            margin-left: 8px;
          }
        }
        .exercise-book-text-content {
          flex: 1;
          padding-bottom: 28px;
        }
        .exercise-book-text {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
        }
        .exercise-book-state {
          display: flex;
          justify-content: space-between;
          color: #1f1f40;
          font-size: 14px;
        }
        .exercise-book-time {
          display: inline-block;
          margin: 15px 30px 0 0;
        }
      }
    }
  }
  .view-btn {
    width: 63px;
    height: 22px;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    line-height: 22px;
  }
  .look-btn {
    background: #ebebf2;
  }
  .finsh-btn {
    background: #3b86ff;
    color: #fff;
  }

  .exercise-book-header {
    display: flex;
    padding: 0 40px;
    height: 90px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  .exercise-book-pagination {
    text-align: center;
    margin-top: 15px;
    &.el-pagination--small {
      .el-pager li,
      .btn-prev,
      .btn-next {
        border: 1px solid rgba(199, 202, 222, 1);
        border-radius: 3px;
        margin: 0 3px;
        color: #c7cade;
      }
      .el-pager li.active {
        border: 1px solid rgba(59, 134, 255, 1);
        color: #3b86ff;
      }
      .btn-prev,
      .btn-next {
        padding: 0 4px;
      }
      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        border: none;
      }
    }
  }
}
</style>
